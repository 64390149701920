"use client";

import { PHIA_COOKIE_ID } from "@/constants";
import { MixpanelEventName } from "@/types";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import useAnalytics from "./useAnalytics";
import { useNavigationEvents } from "./useNavigationEvents";
import usePhiaSearchParams from "./usePhiaSearchParams";

export default function useRouteChangeTracker() {
  const url = useNavigationEvents();
  const { logMixpanelEvent } = useAnalytics();
  const searchParams = usePhiaSearchParams();
  const phiaIdParam = searchParams.get(PHIA_COOKIE_ID);
  const phiaIdCookie = Cookies.get(PHIA_COOKIE_ID);

  const phiaId = phiaIdParam ?? phiaIdCookie ?? null;

  const handleRouteChange = useCallback(() => {
    logMixpanelEvent({
      name: MixpanelEventName.PAGE_VIEW,
      properties: {
        phia_id: phiaId,
      },
    });
  }, [logMixpanelEvent, phiaId]);

  useEffect(() => {
    handleRouteChange();
    // Disabling exhaustive dependencies to only run when the URL changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
}
